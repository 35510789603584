import React, { Component } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import Logo from "../../../../Image/Logo.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import ThaID from "../../../../Image/ThaID.png";
import "./Login.css";
import { Link, Redirect } from "react-router-dom";
import Close from "../../../img/icon/close2.svg";
import Info from "../../../../Image/Info_DLT_2.png";

export class MainAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowCookie: false,
      modalVisible: false,
    };
  }
  cookie() {
    this.setState({
      isShowCookie: true,
    });
    localStorage.setItem("Cookie", true);
  }

  _click() {
    this.setState({ modalVisible: false });
  }

  LoginThaiD() {
    return window.open(
      `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=NXBoWEduUmlXNVByMXhSQmNsZDRDNnNrSDVtRFlDS2Y&redirect_uri=${process.env.REACT_APP_URI}/dltsmartqueue/callback/thaid/&scope=pid%20given_name%20family_name`
    );
    // return window.open("dltsmartqueue://");
  }
  render() {
    return (
      <div className="content-login">
        {/* row-logo */}
        <Row className="row-Logo-login" justify="center" align="top">
          {/* xs < 576 sm >=576 xl >=1200 */}
          <Col className="col-Logo-login" xs={20} sm={8} xl={6}>
            <img className="logo-login-page" src={Logo} />
          </Col>
        </Row>
        <Row justify="center" className="row-main">
          <span className="textonly">สำหรับคนไทย</span>
        </Row>
        <Row justify="center" className="row-main">
          <button
            className="button-main"
            shape="round"
            onClick={() => {
              this.LoginThaiD();
            }}
          >
            <img src={ThaID} className="icon-thaid" />
            <span className="textonly">เข้าสู่ระบบด้วย ThaID</span>
          </button>
        </Row>
        <Row justify="center" className="row-main">
          <span
            className="textunderline"
            onClick={() => {
              this.setState({ modalVisible: true });
            }}
          >
            วิธีการเข้าใช้งานสำหรับคนไทย
          </span>
        </Row>
        <div className="hr" />
        <Row justify="center" className="row-main">
          <span className="textonly">สำหรับคนต่างชาติ</span>
        </Row>
        <Row justify="center" className="row-second">
          <Link to="/login">
            <button className="button-main" shape="round">
              <span className="textonly">Login For Foreigner</span>
            </button>
          </Link>
        </Row>
        <Row justify="center" className="row-main row-last">
          <Link to="/register">
            <button className="button-main" shape="round">
              <span className="textonly">Register For Foreigner</span>
            </button>
          </Link>
        </Row>
        <div>
          <Modal
            visible={this.state.modalVisible}
            footer={null}
            styles={{ height: "max-content", width: "max-content" }}
            onCancel={() => this._click()}
            closeIcon={<img src={Close} />}
          >
            <div className="info-dlt">
              <img src={Info} className="info-photo-dlt" />
            </div>
          </Modal>
        </div>
        {this.state.isShowCookie == false || this.state.isShowCookie == null ? (
          <Row className="row-tab-cookie" align="middle">
            <Col xs={24} sm={24} xl={24} className="tab-cookie">
              <Row align="middle" className="column-cookie">
                <Col
                  xs={{ span: 24, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                  xl={{ span: 19, offset: 1 }}
                  className="col-text-cookie"
                >
                  <h5 className="text-cookie">
                    การเข้าใช้งานเว็บไซต์ถือเป็นการยอมรับใน
                    <a
                      href="https://app-dlt-gecc.theassistech.co.th/dlt-api1/getAnnounce/นโยบายคุ้มครองข้อมูลส่วนบุคคล.PDF"
                      target="_blank"
                    >
                      นโยบายคุ้มครองข้อมูลส่วนบุคคล
                    </a>
                    และ
                    <a
                      href="https://app-dlt-gecc.theassistech.co.th/dlt-api1/getAnnounce/นโยบายคุ้มครองข้อมูลส่วนบุคคลของกรมการขนส่งทางบก.pdf"
                      target="_blank"
                    >
                      นโยบายคุ้มครองข้อมูลส่วนบุคคลของกรมการขนส่งทางบก
                    </a>
                    เว็บไซต์นี้ มีการเก็บข้อมูลการใช้งานเว็บไซต์ (Cookies)
                    เพื่อมอบบริการที่ดีที่สุดสำหรับท่าน โดยการเข้าใช้งานเว็บไซต์
                    ถือเป็นการยอมรับใน เงื่อนไขการใช้งานเว็บไซต์
                  </h5>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 2 }}
                  xl={{ span: 2 }}
                  className={"col-btn-cookie"}
                >
                  <Button className="btn-cookie" onClick={() => this.cookie()}>
                    ตกลง
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default MainAuth;
